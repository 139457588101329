"use client"

import { IconCode } from "@tabler/icons-react"
import { useTranslations } from "next-intl"
import { useState } from "react"

import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/Sheet"
import { Spacer } from "@/components/ui/Spacer"
import { DevtoolsImpersonate } from "@/sections/devtools/DevtoolsImpersonate"

type Props = {
  users: { email: string; tenantId: string; id: string }[]
  tenants: { id: string; name: string }[]
}

export function DevtoolsDialog(props: Props) {
  const t = useTranslations("devtools")
  const { users, tenants } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger className="fixed bottom-20 right-0 z-50 flex h-8 w-8 items-center justify-center rounded-l-md bg-slate-200 hover:bg-slate-300">
          <IconCode className="h-4 w-4 text-slate-700" />
        </SheetTrigger>

        <SheetContent>
          <SheetHeader>
            <SheetTitle>{t("title")}</SheetTitle>
          </SheetHeader>

          <Spacer className="h-8" />

          <DevtoolsImpersonate onClose={() => setIsOpen(false)} users={users} tenants={tenants} />
        </SheetContent>
      </Sheet>
    </>
  )
}
