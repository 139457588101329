"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslations } from "next-intl"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Card, CardContent, CardFooter } from "@/components/ui/Card"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSubmitButton,
} from "@/components/ui/Form"
import { Input } from "@/components/ui/Input"
import { useToastFactory } from "@/components/ui/Sonner"
import { LINKS } from "@/constants/links"
import { useRouter } from "@/i18n/routing"
import { api } from "@/trpc/react"

const formSchema = (texts: { min: string; email: string }) =>
  z.object({
    email: z.string().min(1, texts.min).email(texts.email),
  })

type FormValues = z.infer<ReturnType<typeof formSchema>>

export function LoginForm(props: { nextUrl?: string }) {
  const t = useTranslations("login_page")
  const toast = useToastFactory()
  const router = useRouter()

  const loginMutation = api.users.login.useMutation({
    onError: (error) => {
      if (error.data?.code === "NOT_FOUND") {
        toast.onError({
          title: t("form.error_not_found"),
          description: t("form.error_not_found_description"),
        })
        return
      }

      toast.onError()
    },
    onSuccess: () => {
      toast.onSuccess({
        title: t("form.success"),
        description: t("form.success_description"),
      })

      router.push(LINKS.authVerifyRequest)
    },
  })

  const form = useForm<FormValues>({
    resolver: zodResolver(
      formSchema({
        min: t("schema.error_min"),
        email: t("schema.error_email"),
      }),
    ),
    mode: "onBlur",
    defaultValues: { email: "" },
  })

  return (
    <Card className="w-full max-w-[570px] border-2">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(({ email }) =>
            loginMutation.mutate({ email, nextUrl: props.nextUrl ?? LINKS.home }),
          )}
        >
          <CardContent className="px-12 pt-9">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form.email")}</FormLabel>

                  <FormControl>
                    <Input
                      type="email"
                      className="border-2 placeholder:text-nano-gray-800"
                      placeholder={t("form.email_placeholder")}
                      {...field}
                      value={field.value}
                    />
                  </FormControl>

                  <FormDescription className="text-nano-gray-700">{t("form.email_note")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>

          <CardFooter className="px-12 pb-9">
            <FormSubmitButton className="w-full rounded-md max-sm:h-auto">{t("form.submit")}</FormSubmitButton>
          </CardFooter>
        </form>
      </Form>
    </Card>
  )
}
